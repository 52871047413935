import revive_payload_client_8McVVk4bVo from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.0_@unocss+reset@_sb6gejmytp2a2yzy2yjqptwkcy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_hLT32Uh5Oo from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.0_@unocss+reset@_sb6gejmytp2a2yzy2yjqptwkcy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZGpmxkOdhI from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.0_@unocss+reset@_sb6gejmytp2a2yzy2yjqptwkcy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_JtuGMy2EUl from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.0_@unocss+reset@_sb6gejmytp2a2yzy2yjqptwkcy/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_zOOZh9Puwn from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.0_@unocss+reset@_sb6gejmytp2a2yzy2yjqptwkcy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_mVX3VoiBmI from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.0_@unocss+reset@_sb6gejmytp2a2yzy2yjqptwkcy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_GtDq9i07wL from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.0_@unocss+reset@_sb6gejmytp2a2yzy2yjqptwkcy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_4s1Mg6gGYE from "/opt/build/repo/node_modules/.pnpm/nuxt-gtag@2.1.0_magicast@0.3.4_rollup@4.18.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import chunk_reload_client_mPu2Vc4Vm0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.0_@unocss+reset@_sb6gejmytp2a2yzy2yjqptwkcy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import koala_client_WKDdOaEvL9 from "/opt/build/repo/plugins/koala.client.ts";
export default [
  revive_payload_client_8McVVk4bVo,
  unhead_hLT32Uh5Oo,
  router_ZGpmxkOdhI,
  debug_JtuGMy2EUl,
  payload_client_zOOZh9Puwn,
  check_outdated_build_client_mVX3VoiBmI,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GtDq9i07wL,
  plugin_client_4s1Mg6gGYE,
  chunk_reload_client_mPu2Vc4Vm0,
  koala_client_WKDdOaEvL9
]